// Le composant "Skills" correspond à la section "Compétences" du site. Il contient notamment le composant "Skill" qui permet l'affichage
// des logos de la stack. Ces logos sont passés en props aux composants enfants "Skill".
// On utilise intersection-observer pour gérer le déclenchement d'animations au scroll.

import React from "react";
import { useInView } from "react-intersection-observer";
import Skill from "./Skill";
import skillsStyles from "../styles/components/services.module.scss";

const Skills = () => {
  const options = {
    threshold: 0.1,
  };

  // On définit plusieurs ancrages avec le hook useInView pour déclencher des animations au scroll à différents endroits de la section.
  const { ref: skillsRef, inView: skillsIsVisible } = useInView(options);
  const { ref: logosRef, inView: logosIsVisible } = useInView(options);

  return (
    <section className={skillsStyles.skillsContainer} id="services">
      <h2
        className={`${skillsStyles.skillsTitle} ${
          skillsIsVisible ? skillsStyles.skillsAnimate : ""
        }`}
        ref={skillsRef}
      >
        Services
      </h2>
      <div className={skillsStyles.logosContainer} ref={logosRef}>
        <div
          className={`${skillsStyles.logosFrontend} ${
            logosIsVisible ? skillsStyles.logosFrontendAnimate : ""
          }`}
        >
          <Skill name={"Correction"} />
          <Skill name={"Réécriture"} />
          <Skill name={"Rédaction"} />
          <Skill name={"Rédaction SEO"} />
          <Skill name={"Expression orale"} />
        </div>
        <div
          className={`${skillsStyles.logosBackend} ${
            logosIsVisible ? skillsStyles.logosBackendAnimate : ""
          }`}
        >
          <Skill name={"Grammaire"} />
          <Skill name={"Orthographe"} />
          <Skill name={"Syntaxe"} />
          <Skill name={"Ponctuation & style"} />
          <Skill name={"Cohérence & harmonisation"} />
        </div>
      </div>
    </section>
  );
};

export default Skills;
