// Le composant "Home" correspond à la section "Marianne Boyer - Portfolio" du site.
// On utilise intersection-observer pour gérer le déclenchement d'animations au scroll.

import React from "react";
import { useInView } from "react-intersection-observer";
import homeStyles from "../styles/components/home.module.scss";
import photo from "../assets/photo.jpg";

const Home = () => {
  const options = {
    threshold: 0.3,
  };

  // On définit plusieurs ancrages avec le hook useInView pour déclencher des animations au scroll à différents endroits de la section.
  const { ref: homeRef, inView: homeIsVisible } = useInView(options);
  const { ref: imgRef, inView: imgIsVisible } = useInView(options);

  return (
    <section className={homeStyles.homeContainer} id="home">
      <h1
        className={`${homeStyles.homeTitle} ${
          homeIsVisible ? homeStyles.titleAnimate : ""
        }`}
        ref={homeRef}
      >
        Marianne Boyer
        <br />
        Correctrice-relectrice & rédactrice
      </h1>
      <div className={homeStyles.homeWrapper}>
        <div className={homeStyles.textContainerLeft}>
          <p>
            Pour différencier la grammaire et la linguistique, on a l’habitude
            de dire que la première est prescriptive et que la seconde est
            descriptive. En tant que correctrice-relectrice, je suis bien sûr
            les prescriptions de la grammaire, mais j’aime par-dessus tout
            adopter l’approche de la linguistique et observer, constater,
            m’amuser de la langue française, parce que je la considère comme un
            terrain de jeux infini.
          </p>
        </div>
        <img
          ref={imgRef}
          src={photo}
          alt="Portrait Marianne Boyer"
          className={`${homeStyles.img}
          ${imgIsVisible ? homeStyles.animateImg : ""}`}
        />
        <div className={homeStyles.textContainerRight}>
          <p>
            Depuis 10 ans, c’est dans cet esprit que je corrige, réécris et
            rédige les écrits et projets que vous me confiez. Je mets à leur
            service mon enthousiasme pour le choix du mot juste et mon goût pour
            la nuance, dans le respect des territoires d’expression que vous
            avez définis et des objectifs que doit atteindre votre propos. C’est
            ce que méritent le fond et la forme de vos contenus.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Home;
