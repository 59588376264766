// Le composant "Card" est visible dans la section "Projets" du site. Il permet l'affichage dynamique des données contenues dans le
// fichier projects.json, qui répertorie les informations relatives à chacun des projets de développement terminés.

import React from "react";
import cardStyles from "../styles/components/card.module.scss";
import DataFile from "../data/projects.json";

// La logique du composant commence par une méthode .map appliquée au fichier projects.json pour itérer sur chacun des objets
// qu'il contient.

const Card = () => {
  return (
    <div className={cardStyles.cardContainer}>
      {DataFile.map((project) => (
        <div className={cardStyles.card}>
          <div className={cardStyles.reason}>
            <p className={cardStyles.reasonNumber}>{project.id}</p>
            <h3 className={cardStyles.reasonTitle}>{project.reasonName}</h3>
          </div>
          <div className={cardStyles.infos}>
            <p className={cardStyles.details}>{project.infos}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Card;