// Le composant "Contact" correspond à la section "Contact" du site. Il contient les liens vers le profil Linkedin et le profil GitHub,
// un lien de téléchargement du CV, et un bouton contenant l'adresse e-mail qui, au clic, ouvre l'application de rédaction d'e-mails configurée
// par défaut sur la machine du visiteur.
// On utilise intersection-observer pour gérer le déclenchement d'animations au scroll.

import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import contactStyles from "../styles/components/contact.module.scss";
import Skill from "./Skill";
import logoLinkedin from "../assets/logo_Linkedin.svg";
import logoMalt from "../assets/logo_Malt.svg";
import CV from "../assets/CV.pdf";

const Contact = () => {
  // On utilise le hook useState pour gérer l'affichage conditionnel d'un petit paragraphe au clic sur le bouton contenant
  // l'adresse e-mail. La fonction "display", lorsqu'elle est appelée, permet de changer ce state.
  const [text, setText] = useState(false);

  const display = () => {
    setText(!text);
  };

  const options = {
    threshold: 0.1,
  };

  // On définit plusieurs ancrages avec le hook useInView pour déclencher des animations au scroll à différents endroits de la section.
  const { ref: contactRef, inView: contactIsVisible } = useInView(options);
  const { ref: logosRef, inView: logosIsVisible } = useInView(options);
  const { ref: cvRef, inView: cvIsVisible } = useInView(options);

  return (
    <section className={contactStyles.contactContainer} id="contact">
      <h2
        className={`${contactStyles.contactTitle} ${
          contactIsVisible ? contactStyles.contactAnimate : ""
        }`}
        ref={contactRef}
      >
        Contact
      </h2>

      <section>
        <div className={contactStyles.contactIntro}>
          <p>
            Besoin d'un devis ou d'un renseignement&nbsp;? Contactez-moi par e-mail,
            via Linkedin ou via Malt&nbsp;!
          </p>
          <p ref={logosRef}>
            Le site que vous parcourez actuellement a été conçu par mes soins,
            de A&nbsp;à Z. N'hésitez pas à visiter mon portfolio de
            développeuse web front-end pour voir d'autres projets.
          </p>
        </div>
      </section>

      <div className={contactStyles.socialMediaContainer}>
        <div
          className={`${
            logosIsVisible ? contactStyles.linkedinLogoAnimate : ""
          }`}
        >
          <a
            href={`https://www.linkedin.com/in/marianne-boyer-devfrontend/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Skill src={logoLinkedin} name={""} alt={"logo de Linkedin"} />
          </a>
        </div>
        <div
          className={`${logosIsVisible ? contactStyles.maltLogoAnimate : ""}`}
        >
          <a
            href={`https://www.malt.fr/profile/marianneboyer`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Skill src={logoMalt} name={""} alt={"logo de Malt"} />
          </a>
        </div>
        <div
          className={`${logosIsVisible ? contactStyles.githubLogoAnimate : ""}`}
        >
          <a
            href={`https://marianne-boyer-portfolio.netlify.app`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Skill name={"Portfolio Développement web"} />
          </a>
        </div>
      </div>

      <div
        download={CV}
        className={`${cvIsVisible ? contactStyles.curriculumContainer : ""}`}
        ref={cvRef}
      >
        <a
          href={CV}
          download="CV_MarianneBoyer_correction_&_rédaction"
          className={contactStyles.curriculumLink}
        >
          <p>CV</p>
          <FontAwesomeIcon icon={faDownload} />
        </a>
      </div>

      <div className={contactStyles.emailAndSecretContainer} onClick={display}>
        <div className={contactStyles.emailContainer}>
          <FontAwesomeIcon
            icon={faPaperPlane}
            className={contactStyles.emailIcon}
          />
          <a
            className={contactStyles.emailText}
            href="mailto:mboyer.devweb@gmail.com"
          >
            <div className={contactStyles.desktopText}>
              mboyer.correction.relecture@gmail.com
            </div>
          </a>
        </div>
        <div className={contactStyles.secretContainer}>
          {text && (
            <p className={contactStyles.secretText}>
              Excellente initiative ! 🤝
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
